import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Tag.module.css'

const Tag = ({ className, style, children }) => (
  <span className={cx(styles.root, className)} style={style}>
    {children}
  </span>
)

Tag.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Tag
