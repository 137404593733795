import React from 'react'

import { FaFacebookF, FaGoogle } from 'react-icons/fa'

import Lead from '@components/Lead'
import Link from '@components/Link'
import Button from '@components/Button'
import PaddedBlock from '@components/PaddedBlock'
import SEO from '@components/SEO'
import Tag from '@components/Tag'

import styles from './reviews.module.css'

const socialButtons = (
  <div className={styles.more}>
    <Button
      className={styles.btn}
      external
      to="https://www.google.com/maps/place/My+Christina+Yacht/@30.0396615,31.2181767,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0x9069301586abf87c!8m2!3d30.0396615!4d31.2203654!9m1!1b1"
    >
      <FaGoogle />
      <span>Google Reviews</span>
    </Button>
    <Button className={styles.btn} external to="https://www.facebook.com/pg/Christina-yacht-526372210718910/community/">
      <FaFacebookF />
      <span>Facebook Reviews</span>
    </Button>
  </div>
)

const ReviewsPage = () => (
  <React.Fragment>
    <SEO title="Reviews" />

    <PaddedBlock>
      <Lead>Your reviews matter to us</Lead>
      <Tag className={styles.banner}>
        <p>If you enjoyed a cruise with us, please take the time to review us on your favorite platform :</p>
        {socialButtons}
        <small className={styles.smaller}>
          or you can always send us your review via <Link to="mailto:yachtchristina@gmail.com">email</Link>
        </small>
      </Tag>
      <div dangerouslySetInnerHTML={{ __html: require('@src/static/reviews.md') }} />
    </PaddedBlock>
  </React.Fragment>
)

export default ReviewsPage
